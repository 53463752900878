import React from 'react';

function RealTimeTracker() {
  return (
    <div className="card shadow-sm">
      <div className="card-body">
        <h5 className="card-title">Real-time Campaign Tracker</h5>
        <p>Live Feed: Campaign A - 100,000 views</p>
        <p>Top Interactions: Positive comments, Shares</p>
      </div>
    </div>
  );
}

export default RealTimeTracker;