import React from 'react';
import TopRow from './toprow';
import MiddleRow from './middlerow';
import BottomRow from './bottomrow';

function RightColumn() {
  return (
      <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-column flex-grow-1" style={{ height: '15vh' }}>
              <TopRow />
          </div>
          <div className="d-flex flex-row flex-grow-1" style={{ height: '20vh' }}>
              <MiddleRow />
              <MiddleRow />
          </div>
          <div className="d-flex flex-column flex-grow-1" style={{ height: '60vh' }}>
              <BottomRow />
          </div>
      </div>
  );
}

export default RightColumn;