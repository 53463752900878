import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default function BootstrapCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [hoveredDay, setHoveredDay] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [newEvent, setNewEvent] = useState({ title: '', type: 'meeting' });

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const generateCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const calendar = [];

    let day = 1;
    for (let i = 0; i < 6; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDayOfMonth) {
          week.push(null);
        } else if (day > daysInMonth) {
          week.push(null);
        } else {
          week.push(day);
          day++;
        }
      }
      calendar.push(week);
      if (day > daysInMonth) break;
    }

    return calendar;
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleDayClick = (day) => {
    if (day) {
      const clickedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      setSelectedDate(clickedDate);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewEvent({ title: '', type: 'meeting' });
  };

  const handleSaveEvent = () => {
    if (selectedDate && newEvent.title) {
      const dateKey = selectedDate.toISOString().split('T')[0];
      setEvents(prev => ({
        ...prev,
        [dateKey]: [...(prev[dateKey] || []), newEvent]
      }));
      handleCloseModal();
    }
  };

  const handleDeleteEvent = (dateKey, index) => {
    const updatedEvents = { ...events };
    updatedEvents[dateKey].splice(index, 1);
    if (updatedEvents[dateKey].length === 0) {
      delete updatedEvents[dateKey];
    }
    setEvents(updatedEvents);
  };

  const handleEditEvent = (dateKey, index) => {
    const eventToEdit = events[dateKey][index];
    setNewEvent(eventToEdit);
    setSelectedDate(new Date(dateKey));
    setShowModal(true);
  };

  const getEventColor = (type) => {
    switch (type) {
      case 'meeting':
        return 'bg-primary';
      case 'todo':
        return 'bg-success';
      default:
        return 'bg-secondary';
    }
  };

  const handleMouseEnter = (day) => {
    if (day) {
      const dateKey = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0];
      if (events[dateKey]) {
        setHoveredDay(day);
      }
    }
  };

  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  const getDayBackground = (day) => {
    const today = new Date();
    if (!day) return '';
    if (
      day === today.getDate() &&
      currentDate.getMonth() === today.getMonth() &&
      currentDate.getFullYear() === today.getFullYear()
    ) {
      return 'bg-warning'; // Current day
    }
    if ([0, 6].includes(new Date(currentDate.getFullYear(), currentDate.getMonth(), day).getDay())) {
      return 'bg-info'; // Weekend days
    }
    return 'bg-white'; // Regular days
  };

  return (
    <div className="container h-100 d-flex flex-column p-3">
      <div className="d-flex justify-content-between align-items-center mb-3 bg-light p-2 rounded">
        <button className="btn btn-outline-primary" onClick={handlePrevMonth}>&lt;</button>
        <h6 className="text-center text-primary mb-0">{months[currentDate.getMonth()]} {currentDate.getFullYear()}</h6>
        <button className="btn btn-outline-primary" onClick={handleNextMonth}>&gt;</button>
      </div>
      <div className="row text-center fw-bold mb-2 bg-dark text-white rounded p-2">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="col">{day}</div>
        ))}
      </div>
      {generateCalendar().map((week, weekIndex) => (
        <div key={weekIndex} className="row g-0">
          {week.map((day, dayIndex) => {
            const dateKey = new Date(currentDate.getFullYear(), currentDate.getMonth(), day).toISOString().split('T')[0];
            return (
              <div
                key={dayIndex}
                className={`col border p-2 text-center ${getDayBackground(day)} position-relative`}
                onClick={() => handleDayClick(day)}
                onMouseEnter={() => handleMouseEnter(day)}
                onMouseLeave={handleMouseLeave}
                style={{ minHeight: '30px', maxHeight: '65px', cursor: 'pointer' }}
              >
                {day && (
                  <>
                    <span className="fw-bold d-block">{day}</span>
                    {events[dateKey]?.map((event, index) => (
                      <span
                        key={index}
                        className={`d-inline-block rounded-circle ${getEventColor(event.type)} mx-1`}
                        style={{ width: '10px', height: '10px' }}
                      ></span>
                    ))}
                    {hoveredDay === day && (
                      <div className="popover fade show bg-white p-2 border rounded shadow position-absolute"
                        style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', zIndex: 1, width: '220px' }}
                      >
                        {events[dateKey]?.map((event, index) => (
                          <div key={index} className="d-flex justify-content-between align-items-center mb-1">
                            <span className={`d-inline-block rounded-circle ${getEventColor(event.type)} me-2`} style={{ width: '10px', height: '10px' }}></span>
                            <span>{event.title}</span>
                            <span>
                              <i className="bi bi-pencil text-primary me-2" style={{ cursor: 'pointer' }} onClick={() => handleEditEvent(dateKey, index)}></i>
                              <i className="bi bi-trash text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDeleteEvent(dateKey, index)}></i>
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      ))}
      {showModal && (
        <div className="modal d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Event</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="eventTitle" className="form-label">Event Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="eventTitle"
                      placeholder="Enter event title"
                      value={newEvent.title}
                      onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="eventType" className="form-label">Event Type</label>
                    <select
                      id="eventType"
                      className="form-select"
                      value={newEvent.type}
                      onChange={(e) => setNewEvent({ ...newEvent, type: e.target.value })}
                    >
                      <option value="meeting">Meeting</option>
                      <option value="todo">Todo</option>
                      <option value="event">Event</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                <button type="button" className="btn btn-primary" onClick={handleSaveEvent}>Save Event</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
