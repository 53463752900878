import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AccountStatusCard from './components/settings/AccountStatusCard';
import ChangePasswordCard from './components/settings/ChangePasswordCard';
import UpdateEmailCard from './components/settings/UpdateEmailCard';
import NotificationPreferencesCard from './components/settings/NotificationPreferencesCard';

const SettingsPage = () => {
    const [isActive, setIsActive] = useState(true); // Account activation status
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [notifications, setNotifications] = useState(true); // Notification preference

    const handleToggleActivation = () => {
        setIsActive(!isActive);
        alert(`Account has been ${!isActive ? 'activated' : 'deactivated'}`);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add logic to update the settings in your app, e.g., send to server
        alert('Settings updated successfully.');
    };

    return (
        <div className="container my-5">
            <h2 className="mb-4">Settings</h2>
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-row flex-wrap gap-3">
                    <AccountStatusCard isActive={isActive} onToggleActivation={handleToggleActivation} />
                    <ChangePasswordCard password={password} onPasswordChange={setPassword} />
                    <UpdateEmailCard email={email} onEmailChange={setEmail} />
                    <NotificationPreferencesCard notifications={notifications} onToggleNotifications={() => setNotifications(!notifications)} />
                </div>

                <div className="mt-4">
                    <button type="submit" className="btn btn-primary">
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SettingsPage;
