import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PublisherDashboard from './components/dashboard/user/user-main';
import CompanyDashboard from './components/dashboard/company/company-main';
import LoginPage from './components/home';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* Route to the login page */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/dashboard/company" element={<CompanyDashboard />} />
          <Route path="/dashboard/user" element={<PublisherDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
