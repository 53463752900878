import React from 'react';

const BillingStatusCard = () => {
  const totalBilled = "$500"; // Example value for Total Billed
  const lastBilled = "$50"; // Example value for Last Billed
  const monthlyBilling = "$50"; // Example value for Monthly Billing

  return (
    <div className="container-fluid d-flex" style={{minWidth: '40%'}}>
      <div className="card shadow-lg w-100" style={{ width: '100%', height: '100%', borderRadius: '15px' }}>
        <div className="card-body d-flex flex-column align-items-center justify-content-center" style={{ height: '100%' }}>
          <h2 className="text-primary mb-4" style={{ fontSize: '28px' }}>
            Total Billed
          </h2>
          <p className="text-dark mb-4" style={{ fontSize: '28px', fontWeight: 'bold' }}>{totalBilled}</p>
          <p style={{ fontSize: '28px', color: 'grey'}}>-------------------------------------------------------------------</p>

          <h2 className="text-primary mb-4" style={{ fontSize: '28px' }}>
            Last Billed
          </h2>
          <p className="text-dark mb-4" style={{ fontSize: '28px', fontWeight: 'bold' }}>{lastBilled}</p>
          <p style={{ fontSize: '28px', color: 'grey'}}>-------------------------------------------------------------------</p>

          <h2 className="text-primary mb-4" style={{ fontSize: '28px' }}>
            Monthly Billing
          </h2>
          <p className="text-dark" style={{ fontSize: '28px', fontWeight: 'bold' }}>{monthlyBilling}</p>
        </div>
      </div>
    </div>
  );
};

export default BillingStatusCard;
