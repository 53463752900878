import React from 'react';
import ImageCard from './imagecard';
import img1 from '../../../../res/img.png';
import 'bootstrap/dist/css/bootstrap.min.css'; 



function MiddleRow() {
    const cardData = [
      { imageSrc: img1, name: 'Name 1' },
      { imageSrc: img1, name: 'Name 2' },
      { imageSrc: img1, name: 'Name 3' },
      { imageSrc: img1, name: 'Name 4' },
      { imageSrc: img1, name: 'Name 5' },
      { imageSrc: img1, name: 'Name 6' },
    ];
  
    return (
      <div className="container-fluid h-100">
        <div className="d-flex flex-row flex-grow-1">
          {cardData.map((card, index) => (
            <div key={index} className="col-6 col-md-4 col-lg-2 d-flex justify-content-center mb-3">
              <ImageCard imageSrc={card.imageSrc} name={card.name} />
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default MiddleRow;