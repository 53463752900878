import React from 'react';
import '../../../../styles/common/Card.css';

function Card({ title, textLine1 }) {
  return (
    <div className="card">
      <h1>{title}</h1>
      <h4>{textLine1}</h4>
    </div>
  );
}

export default Card;
