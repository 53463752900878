import React from 'react';

const ChangePasswordCard = ({ password, onPasswordChange }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">Change Password</h5>
        <div className="form-group">
          <label htmlFor="formPassword">New Password</label>
          <input
            type="password"
            className="form-control"
            id="formPassword"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => onPasswordChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordCard;
