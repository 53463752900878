import React from 'react';
import PubLogoSection from './logo';
import PubMenu from './menu';
import Membership from '../../common/membership';


import img1 from '../../../../res/company-logo.png'

function LeftColumn({ setActiveMenuItem, activeMenuItem }) {
  return (
    <div className="d-flex flex-column justify-content-start flex-grow-1 h-100">
      <div className='d-flex flex-column mb-3 rounded' style={{backgroundColor:'#e1f5fb', borderRadius: '15px', border: '1px solid #ccc'}}>
        <PubLogoSection rating={3.5} image={img1}/>
        <Membership membershipCount={3} />
      </div>
      <PubMenu setActiveMenuItem={setActiveMenuItem} activeMenuItem={activeMenuItem} />
    </div>
  );
}

export default LeftColumn;