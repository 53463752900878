import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportList from './components/reports/ReportList';
import ReportDetails from './components/reports/ReportDetails';

// Dummy data for reports (this would come from an API in a real app)
const dummyReports = [
    {
        id: 1,
        creatorName: "Winter Promotion",
        videoUrl: "https://www.youtube.com/embed/VIDEO_ID1",
        views: 1500,
        details: "Campaign for Product A",
        requirements: [
            { id: 1, label: "Mentioned product benefits", fulfilled: true },
            { id: 2, label: "Used product in video", fulfilled: false },
            { id: 3, label: "Included call to action", fulfilled: true }
        ],
        status: "Pending"
    },

    {
        id: 2,
        creatorName: "X-Mas Promotion",
        videoUrl: "https://www.youtube.com/embed/VIDEO_ID1",
        views: 34500,
        details: "Campaign for Product A",
        requirements: [
            { id: 1, label: "Mentioned product benefits", fulfilled: true },
            { id: 2, label: "Used product in video", fulfilled: false },
            { id: 3, label: "Included call to action", fulfilled: true }
        ],
        status: "Pending"
    },

    {
        id: 3,
        creatorName: "NewYear Promotions",
        videoUrl: "https://www.youtube.com/embed/VIDEO_ID1",
        views: 4500,
        details: "Campaign for Product A",
        requirements: [
            { id: 1, label: "Mentioned product benefits", fulfilled: true },
            { id: 2, label: "Used product in video", fulfilled: false },
            { id: 3, label: "Included call to action", fulfilled: true }
        ],
        status: "Pending"
    },

    {
        id: 4,
        creatorName: "Hallowin Promotion",
        videoUrl: "https://www.youtube.com/embed/VIDEO_ID1",
        views: 2500,
        details: "Campaign for Product A",
        requirements: [
            { id: 1, label: "Mentioned product benefits", fulfilled: true },
            { id: 2, label: "Used product in video", fulfilled: false },
            { id: 3, label: "Included call to action", fulfilled: true }
        ],
        status: "Pending"
    },
    // Add more reports as needed
];

const ReportPage = () => {
    const [reports, setReports] = useState(dummyReports);
    const [selectedReport, setSelectedReport] = useState(null);

    const handleSelectReport = (reportId) => {
        const report = reports.find(r => r.id === reportId);
        setSelectedReport(report);
    };

    const handleAction = (action) => {
        if (selectedReport) {
            setReports(reports.map(report => 
                report.id === selectedReport.id ? { ...report, status: action } : report
            ));
            alert(`Report marked as: ${action}`);
        }
    };

    return (
        <div className="container-fluid vh-100 d-flex flex-column">
            <h2 className="mb-4">Reports</h2>
            <div className="d-flex align-items-start"> {/* Added d-flex and align-items-start */}
                {/* Left Section: Report List */}
                <div className="col-4">
                    <ReportList reports={reports} onSelectReport={handleSelectReport} />
                </div>

                {/* Right Section: Report Details */}
                <div className="col-8">
                    {selectedReport ? (
                        <ReportDetails report={selectedReport} onAction={handleAction} />
                    ) : (
                        <div className="text-center p-4">
                            <p>Select a report to view details</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportPage;
