import React from 'react';
import LineChartComponent from './bottomrow/linechart';
import PieChart from './bottomrow/piechart';
import TabbedSection from './bottomrow/tabbed';



function BottomRow() {
    const pieData1 = {
      labels: ['Red', 'Blue', 'Yellow'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }
      ]
    };
  
    const pieData2 = {
      labels: ['Green', 'Purple', 'Orange'],
      datasets: [
        {
          data: [200, 150, 250],
          backgroundColor: ['#4BC0C0', '#9966FF', '#FF9F40'],
          hoverBackgroundColor: ['#4BC0C0', '#9966FF', '#FF9F40']
        }
      ]
    };
  
    return (
        
            <div className="d-flex flex-row flex-grow-1">                
                    <TabbedSection/>               
                <div className="d-flex flex-column flex-grow-1">
                    <div className="d-flex flex-row flex-grow-1">
                            <PieChart data={pieData1} title="Card Title 1" />
                            <PieChart data={pieData2} title="Card Title 2" />
                            <PieChart data={pieData2} title="Card Title 2" />
                    </div>
                    <div className="d-flex flex-grow-1 align-items-stretch" style={{ height: "50%" }}>
                            <LineChartComponent />
                    </div>
                </div>
            </div>
        
    );
  }
  
  export default BottomRow;