import React from 'react';
import '../../../../styles/user/Rating.css';

function PubLogoSection({ rating, image }) {
  return (
    <div className="logo">
      <div className="profile-container">
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row flex-grow-1">
              <img src={image} alt="Profile" className="img-fluid img-thumbnail img-rounded profile-image" style={{ width: '70px', height: '70px' }} />
              <div className="d-flex flex-column flex-grow-1"> 
              <h4 className="mb-0 mt-2" style={{paddingLeft: '.5rem'}}>YouTube</h4>
              <div className="star-rating mb-0 mt-0">
                        {[...Array(5)].map((_, index) => (
                  <Star key={index} filled={getStarType(index, rating)} />
                        ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Star({ filled }) {
  return (
    <span className={`star ${filled}`}>&#9733;</span>  // Unicode star character
  );
}

function getStarType(index, rating) {
  const roundedRating = Math.round(rating * 2) / 2;  // Round to nearest half
  if (index + 1 <= roundedRating) return 'filled';  // Full star
  if (index + 0.5 === roundedRating) return 'half-filled';  // Half star
  return 'empty';  // Empty star
}

export default PubLogoSection;