import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'bootstrap/dist/css/bootstrap.min.css';

function PieChart({ data, title }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = new Chart(chartRef.current, {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: true, // Maintain aspect ratio while resizing
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    // Cleanup on component unmount
    return () => {
      chartInstance.destroy();
    };
  }, [data]);

  return (
    <div className="card shadow-sm p-3 mb-3 bg-white rounded d-flex flex-column" style={{ height: '100%', width: '100%' }}>
      <h4 className="text-center">{title}</h4>
      <div 
        className="position-relative" 
        style={{ paddingTop: '100%', width: '100%', overflow: 'hidden' }} // Maintain square aspect ratio
      >
        <canvas ref={chartRef} className="position-absolute" style={{ top: 0, left: 0, width: '100%', height: '100%' }}></canvas>
      </div>
    </div>
  );
}

export default PieChart;
