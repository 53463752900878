import React from 'react';

const NotificationPreferencesCard = ({ notifications, onToggleNotifications }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">Notification Preferences</h5>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="formNotifications"
            checked={notifications}
            onChange={onToggleNotifications}
          />
          <label className="form-check-label" htmlFor="formNotifications">
            Receive email notifications
          </label>
        </div>
      </div>
    </div>
  );
};

export default NotificationPreferencesCard;