import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserProfile = () => {
  // State to hold user details
  const [userDetails, setUserDetails] = useState({
    username: 'Your Username',
    email: 'user@example.com',
    location: 'New York, USA',
    dateOfBirth: '1990-01-01',
    bio: 'This is a short bio about the user...',
    profilePicture: '/img/creator-icon.jpg', // Local image path from public folder
  });

  // State to track if the form is in edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  // Handle profile picture upload
  const handleProfilePictureUpload = (e) => {
    setUserDetails({ ...userDetails, profilePicture: URL.createObjectURL(e.target.files[0]) });
  };

  // Toggle between edit and view mode
  const toggleEdit = () => {
    setIsEditing(!isEditing); // Switch between editing and viewing mode
  };

  // Handle save action
  const handleSave = () => {
    console.log('User Details Saved:', userDetails);
    setIsEditing(false); // After saving, exit edit mode
    alert('User profile saved!');
  };

  return (
    <div className="container mt-5">
      {/* Banner for Profile Picture and Username */}
      <div
        className="d-flex align-items-center justify-content-start mb-4"
        style={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          borderRadius: '8px',
          position: 'relative',
        }}
      >
        <div style={{ flex: '0 0 150px' }}>
          <img
            src={userDetails.profilePicture}
            alt="Profile"
            className="img-fluid"
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
          />
        </div>
        <div className="ms-4">
          <h2>{userDetails.username}</h2>
        </div>
      </div>

      {/* Form - Flexbox Layout */}
      <form>
        {/* Username and Email */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="username" className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={userDetails.username}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={userDetails.email}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
        </div>

        {/* Location and Date of Birth */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="location" className="form-label">Location</label>
            <input
              type="text"
              className="form-control"
              id="location"
              name="location"
              value={userDetails.location}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="dateOfBirth" className="form-label">Date of Birth</label>
            <input
              type="date"
              className="form-control"
              id="dateOfBirth"
              name="dateOfBirth"
              value={userDetails.dateOfBirth}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
        </div>

        {/* Profile Picture Upload and Bio */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="profilePicture" className="form-label">Profile Picture</label>
            <input
              type="file"
              className="form-control"
              id="profilePicture"
              name="profilePicture"
              onChange={handleProfilePictureUpload}
              disabled={!isEditing} // Disable file input when not in edit mode
            />
          </div>
        </div>

        {/* Bio */}
        <div className="mb-3">
          <label htmlFor="bio" className="form-label">Bio</label>
          <textarea
            className="form-control"
            id="bio"
            name="bio"
            rows="4"
            value={userDetails.bio}
            onChange={handleInputChange}
            readOnly={!isEditing} // Field is read-only when not in edit mode
          />
        </div>

        {/* Edit Button */}
        <div className="text-left">
          <button 
            type="button" 
            className={`btn ${isEditing ? 'btn-success bi bi-floppy' : 'btn-primary bi bi-exposure'}`}
            onClick={isEditing ? handleSave : toggleEdit}
          >
            {isEditing ? ' Save Changes' : ' Edit Profile'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;