import React from 'react';
import { Bar } from 'react-chartjs-2';

function CampaignOverview() {
  const data = {
    labels: ['Campaign 1', 'Campaign 2', 'Campaign 3', 'Campaign 4', 'Campaign 5', 'Campaign 6', 'Campaign 7', 'Campaign 8'],
    datasets: [
      {
        label: 'Engagement Rate (%)',
        data: [12.3, 15.8, 9.2, 20.4, 12, 15, 18, 19],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 30,
      },
    },
  };

  return (
    <div className="card w-50 h-100 d-flex flex-column shadow-sm">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">Campaign Performance Overview</h5>
        <div className="flex-grow-1">
            <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default CampaignOverview;