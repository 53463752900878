import React, { useState } from 'react';

const BillingCard = () => {
  const [monthlyAmount] = useState('$49.99');
  const [totalBilled] = useState('$299.94');
  const [billingHistory] = useState([
    { date: '2024-05-01', amount: '$49.99' },
    { date: '2024-06-01', amount: '$49.99' },
    { date: '2024-07-01', amount: '$49.99' },
    { date: '2024-08-01', amount: '$49.99' },
    { date: '2024-09-01', amount: '$49.99' },
    { date: '2024-10-01', amount: '$49.99' },
  ]);

  return (
    <div className="container-fluid d-flex flex-grow-1" style={{minWidth: '60%'}}>
      <div className="card d-flex flex-grow-1 shadow-lg" style={{minWidth: '100%', height: '100%', borderRadius: '15px' }}>
        <div className="card-body">
          <h5 className="text-center mb-4">Billing Information</h5>
          <div className="mb-3">
            <strong>Current Amount:</strong>
            <span className="float-end">{monthlyAmount}</span>
          </div>
          <div className="mb-3">
            <strong>Total Bill:</strong>
            <span className="float-end">{totalBilled}</span>
          </div>
          <h6 className="mt-4">Billing History</h6>
          <ul className="list-group mt-2">
            {billingHistory.map((item, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                <span>{item.date}</span>
                <span>{item.amount}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BillingCard;
