import React from 'react';

const AccountStatusCard = ({ isActive, onToggleActivation }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">Account Status</h5>
        <div className="form-group">
          <label>Status: {isActive ? 'Active' : 'Deactivated'}</label>
          <button
            type="button"
            className={`btn btn-${isActive ? 'danger' : 'success'} ml-3`}
            onClick={onToggleActivation}
          >
            {isActive ? 'Deactivate Account' : 'Activate Account'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountStatusCard;
