import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function TabbedSection() {
  const [activeTab, setActiveTab] = useState('campaigns');

  return (
    <div className="tabbed-section h-100 d-flex flex-column" style={{ maxWidth: '50%' }}>
      {/* Tabbed menu titles */}
      <ul className="nav nav-tabs w-100 bg-white">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'campaigns' ? 'active bg-light' : ''} rounded-0`}
            onClick={() => setActiveTab('campaigns')}
          >
            <i className="fas fa-list me-2"></i>
            Campaign List
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'messages' ? 'active bg-light' : ''} rounded-0`}
            onClick={() => setActiveTab('messages')}
          >
            <i className="fas fa-comments me-2"></i>
            Messages
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'bids' ? 'active bg-light' : ''} rounded-0`}
            onClick={() => setActiveTab('bids')}
          >
            <i className="fas fa-gavel me-2"></i>
            Bids
          </button>
        </li>
      </ul>

      {/* Tabbed content area */}
      <div className="tab-content flex-grow-1 mt-2">
        {activeTab === 'campaigns' && (
          <ul className="list-group" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {[...Array(20).keys()].map(i => (
              <li key={i} className="list-group-item list-group-item-action hover:bg-light">
                Campaign {i + 1}
                <span className="badge bg-info float-end">Active</span> {/* Status tag */}
              </li>
            ))}
          </ul>
        )}
        {activeTab === 'messages' && (
          <ul className="list-group" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {[...Array(20).keys()].map(i => (
              <li key={i} className="list-group-item list-group-item-action hover:bg-light">
                Message {i + 1}
                <span className="badge bg-success float-end">New</span> {/* Status tag */}
              </li>
            ))}
          </ul>
        )}
        {activeTab === 'bids' && (
          <ul className="list-group" style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {[...Array(20).keys()].map(i => (
              <li key={i} className="list-group-item list-group-item-action hover:bg-light">
                Bid {i + 1}
                <span className="badge bg-primary float-end">Pending</span> {/* Status tag */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default TabbedSection;
