import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

/* const userProfile = {
    companyName: "TechInnovators",
    companyLogo: "/img/contenter.jpg"
}; */

function CampaignSearch() {
    const [campaigns] = useState([
        { id: 1, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200" },
        { id: 2, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200" },
        { id: 3, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200" },
        { id: 4, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200" },
        // Add more campaigns as needed
    ]);

    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isApplyFormOpen, setIsApplyFormOpen] = useState(false);

    return (
        <div className="container-fluid">
            {/* Upper Row: Form and Placeholder */}
            <div className="d-flex flex-row flex-grow-1" style={{ height: '20vh', padding: '10px' }}>
            </div>

            <div className="d-flex flex-column flex-grow-1" style={{ height: '65vh' }}>

                {/* Lower Row: Existing Campaigns */}
                <h4 className="mb-3">Existing Campaigns</h4>
                <div className="d-flex flex-row flex-grow-1">
                    {campaigns.map((campaign) => (
                        <div key={campaign.id} className="col-md-3 mb-3 d-flex">
                            <div className="card flex-grow-1" style={{ border: '1px solid grey', backgroundColor: '#f8f9fa', maxHeight: '230px' }}>
                                <div className="card-body">

                                    <div className="d-flex align-items-center mb-2">
                                        <img
                                            alt='company'
                                            src={campaign.companyLogo}
                                            className="rounded-circle me-2"
                                            width="40"
                                            height="40"
                                        />
                                        <span className="fw-normal">{campaign.companyName}</span>
                                    </div>
                                    <h5 className="card-title fw-bold">{campaign.title}</h5>
                                    <p className="card-text"><strong>Budget:</strong> ${campaign.budget}</p>
                                    <p className="card-text"><strong>Requirements:</strong> {campaign.requirements}</p>
                                    <button className="btn btn-dark" onClick={() => setSelectedCampaign(campaign)}>View Details</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Modal for Viewing Details */}
                {selectedCampaign && (
                    <div className="modal show d-block">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{selectedCampaign.title}</h5>
                                    <button type="button" className="btn-close" onClick={() => setSelectedCampaign(null)}></button>
                                </div>
                                <div className="modal-body">
                                    <img src={selectedCampaign.productImage} alt={selectedCampaign.title} className="img-fluid mb-3" />
                                    <p><strong>Company:</strong> {selectedCampaign.companyName}</p>
                                    <p><strong>Budget:</strong> {selectedCampaign.budget}</p>
                                    <p><strong>Description:</strong> {selectedCampaign.description}</p>
                                    <p><strong>Requirements:</strong> {selectedCampaign.requirements}</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={() => setIsApplyFormOpen(true)}>Apply Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {isApplyFormOpen && (
                    <div className="modal show d-block">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Apply for Campaign</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsApplyFormOpen(false)}></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="email" className="form-control" id="email" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="channel" className="form-label">YouTube Channel</label>
                                            <input type="text" className="form-control" id="channel" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="subscribers" className="form-label">Subscribers Count</label>
                                            <input type="number" className="form-control" id="subscribers" />
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit Application</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CampaignSearch;
