import React from 'react';

const ReportList = ({ reports, onSelectReport }) => {
    return (
        <div className="list-group">
            {reports.map(report => (
                <button
                    key={report.id}
                    className="list-group-item list-group-item-action"
                    onClick={() => onSelectReport(report.id)}
                >
                    {report.creatorName} - {report.details}
                </button>
            ))}
        </div>
    );
};

export default ReportList;
