import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function PubMenu({ setActiveMenuItem, activeMenuItem }) {
  const menuItems = [
    { name: 'Dashboard', icon: 'bi-house'},
    { name: 'Campaigns', icon: 'bi bi-badge-ad'},
    { name: 'Analytics', icon: 'bi-graph-up'},
    { name: 'Messaging', icon: 'bi bi-envelope-at'},
    { name: 'Reports', icon: 'bi bi-receipt'},
    { name: 'Billing', icon: 'bi bi-credit-card'},
    { name: 'Profile', icon: 'bi bi-building-add'},
    { name: 'Settings', icon: 'bi-gear'}
  ];

  return (
    <div className="list-group">
      {menuItems.map((item) => (
        <PubMenuItem
          key={item.name}
          item={item}
          active={activeMenuItem === item.name}
          onClick={() => setActiveMenuItem(item.name)}
        />
      ))}
    </div>
  );
}

function PubMenuItem({ item, active, onClick }) {
  return (
    <button
      className={`list-group-item list-group-item-action d-flex align-items-center ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <i className={`${item.icon} me-2`}></i> {/* Add the icon with margin */}
      {item.name}
    </button>
  );
}

export default PubMenu;