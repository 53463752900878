import React from 'react';
import '../../../../styles/user/Rewards.css'; 

import 'bootstrap/dist/css/bootstrap.min.css';

function Rewards({ rewardCount = 5 }) {
  const images = [
    require('../../../../res/awards/silver.jpg'),
    require('../../../../res/awards/golden.jpg'),
    require('../../../../res/awards/diamond.jpg'),
    require('../../../../res/awards/redmond.jpg'),
    require('../../../../res/awards/red-diamond.jpg'),
  ];

  // Ensure rewardCount is between 1 and 5
  const validRewardCount = Math.max(1, Math.min(rewardCount, 5));

  return (
    <div className="rewards-container">
      {images.slice(0, validRewardCount).map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`reward-${index + 1}`}
          className="img-fluid reward-image"
        />
      ))}
    </div>
  );
}

export default Rewards;