import React from 'react';
import PubLogoSection from './logo';
import PubMenu from './menu';
import Rewards from './reward';


import img1 from '../../../../res/publisher.jpg'

function LeftColumn({ setActiveMenuItem, activeMenuItem }) {
  return (
    <div className="container-fluid h-100">
      <PubLogoSection rating={3.5} image={img1}/>
      <Rewards rewardCount={5} />
      <PubMenu setActiveMenuItem={setActiveMenuItem} activeMenuItem={activeMenuItem} />
    </div>
  );
}

export default LeftColumn;