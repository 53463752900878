import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CompanyProfile = () => {
  // State to hold company details
  const [companyDetails, setCompanyDetails] = useState({
    companyName: 'Vloger.live Gmbh',
    industry: 'Technology',
    location: 'New York, USA',
    founded: '2001',
    contactEmail: 'contact@vloger.live',
    description: 'We are a leading tech company...',
    logo: '/img/company.jpg', // Local image path from public folder
  });

  // State to track if the form is in edit mode
  const [isEditing, setIsEditing] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  // Handle logo upload
  const handleLogoUpload = (e) => {
    setCompanyDetails({ ...companyDetails, logo: URL.createObjectURL(e.target.files[0]) });
  };

  // Toggle between edit and view mode
  const toggleEdit = () => {
    setIsEditing(!isEditing); // Switch between editing and viewing mode
  };

  // Handle save action
  const handleSave = () => {
    console.log('Company Details Saved:', companyDetails);
    setIsEditing(false); // After saving, exit edit mode
    alert('Company profile saved!');
  };

  return (
    <div className="container mt-5">
      {/* Banner for Company Logo and Name */}
      <div
        className="d-flex align-items-center justify-content-start mb-4"
        style={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          borderRadius: '8px',
          position: 'relative',
        }}
      >
        <div style={{ flex: '0 0 150px' }}>
          <img
            src={companyDetails.logo}
            alt="Company Logo"
            className="img-fluid"
            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
          />
        </div>
        <div className="ms-4">
          <h2>{companyDetails.companyName}</h2>
        </div>
      </div>

      {/* Form - Flexbox Layout */}
      <form>
        {/* Company Name and Industry */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="companyName" className="form-label">Company Name</label>
            <input
              type="text"
              className="form-control"
              id="companyName"
              name="companyName"
              value={companyDetails.companyName}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="industry" className="form-label">Industry</label>
            <input
              type="text"
              className="form-control"
              id="industry"
              name="industry"
              value={companyDetails.industry}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
        </div>

        {/* Location and Founded */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="location" className="form-label">Location</label>
            <input
              type="text"
              className="form-control"
              id="location"
              name="location"
              value={companyDetails.location}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="founded" className="form-label">Founded</label>
            <input
              type="text"
              className="form-control"
              id="founded"
              name="founded"
              value={companyDetails.founded}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
        </div>

        {/* Contact Email and Logo Upload */}
        <div className="d-flex mb-3">
          <div className="me-3" style={{ flex: 1 }}>
            <label htmlFor="contactEmail" className="form-label">Contact Email</label>
            <input
              type="email"
              className="form-control"
              id="contactEmail"
              name="contactEmail"
              value={companyDetails.contactEmail}
              onChange={handleInputChange}
              readOnly={!isEditing} // Field is read-only when not in edit mode
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="logo" className="form-label">Company Logo</label>
            <input
              type="file"
              className="form-control"
              id="logo"
              name="logo"
              onChange={handleLogoUpload}
              disabled={!isEditing} // Disable file input when not in edit mode
            />
          </div>
        </div>

        {/* Description */}
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            name="description"
            rows="4"
            value={companyDetails.description}
            onChange={handleInputChange}
            readOnly={!isEditing} // Field is read-only when not in edit mode
          />
        </div>

        {/* Edit Button */}
        <div className="text-left">
          <button 
            type="button" 
            className={`btn ${isEditing ? 'btn-success bi bi-floppy' : 'btn-primary bi bi-exposure'}`}
            onClick={isEditing ? handleSave : toggleEdit}
          >
            {isEditing ? ' Save Changes' : ' Edit Profile'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyProfile;