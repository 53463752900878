import React from 'react';
import '../../../../styles/common/ImageCard.css';


function ImageCard({ imageSrc, name }) {
  return (
    <div className="image-card">
      <img src={imageSrc} alt={name} className="card-image" />
      <h4 className="card-name">{name}</h4>
    </div>
  );
}

export default ImageCard;