import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ColorfulCalendar from '../../utils/Calender';

export default function CompanyMessaging() {
  const [activeTab, setActiveTab] = useState('inbox');
  const [messages, setMessages] = useState([
    { id: 1, from: 'John Doe', subject: 'Hello', content: 'Hi there! This is a longer message content to demonstrate how it looks in the detailed view.', folder: 'inbox' },
    { id: 2, from: 'Jane Smith', subject: 'Meeting', content: 'Can we meet tomorrow? I have some important topics to discuss regarding our ongoing project.', folder: 'inbox' },
    { id: 3, to: 'Alice Johnson', subject: 'Report', content: 'Here\'s the report you requested. Please review it and let me know if you need any changes or have any questions.', folder: 'sent' },
    { id: 4, from: 'Bob Wilson', subject: 'Important', content: 'Please review this ASAP. We need to submit the proposal by end of day.', folder: 'saved' },
    { id: 5, from: 'John Doe', subject: 'Hello', content: 'Hi there! This is a longer message content to demonstrate how it looks in the detailed view.', folder: 'inbox' },
    { id: 6, from: 'Jane Smith', subject: 'Meeting', content: 'Can we meet tomorrow? I have some important topics to discuss regarding our ongoing project.', folder: 'inbox' },
    { id: 7, to: 'Alice Johnson', subject: 'Report', content: 'Here\'s the report you requested. Please review it and let me know if you need any changes or have any questions.', folder: 'sent' },
    { id: 8, from: 'Bob Wilson', subject: 'Important', content: 'Please review this ASAP. We need to submit the proposal by end of day.', folder: 'saved' },
    { id: 9, from: 'John Doe', subject: 'Hello', content: 'Hi there! This is a longer message content to demonstrate how it looks in the detailed view.', folder: 'inbox' },
    { id: 10, from: 'Jane Smith', subject: 'Meeting', content: 'Can we meet tomorrow? I have some important topics to discuss regarding our ongoing project.', folder: 'inbox' },
      ]);

  const [newMessage, setNewMessage] = useState({ to: '', subject: '', content: '' });
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setSelectedMessage(null);
  };

  const handleNewMessageChange = (e) => {
    const { name, value } = e.target;
    setNewMessage(prev => ({ ...prev, [name]: value }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const newId = messages.length + 1;
    const sentMessage = { id: newId, ...newMessage, folder: 'sent' };
    setMessages([...messages, sentMessage]);
    setNewMessage({ to: '', subject: '', content: '' });
    setActiveTab('sent');
  };

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
  };

  const handleBackClick = () => {
    setSelectedMessage(null);
  };

  const filteredMessages = messages.filter(message => message.folder === activeTab);

  return (
    <div className="container-fluid vh-100 d-flex flex-column">

      <div className="row align-items-start h-50" style={{padding: '10px'}}>
        <div className="col-md-3 mb-3 align-items-start">
          <div className="list-group">
            <button 
              className={`list-group-item list-group-item-action ${activeTab === 'inbox' ? 'active' : ''}`}
              onClick={() => handleTabChange('inbox')}
            >
              <i className="bi bi-inbox me-2"></i> Inbox
            </button>
            <button 
              className={`list-group-item list-group-item-action ${activeTab === 'sent' ? 'active' : ''}`}
              onClick={() => handleTabChange('sent')}
            >
              <i className="bi bi-send me-2"></i> Sent
            </button>
            <button 
              className={`list-group-item list-group-item-action ${activeTab === 'saved' ? 'active' : ''}`}
              onClick={() => handleTabChange('saved')}
            >
              <i className="bi bi-star me-2"></i> Saved
            </button>
            <button 
              className={`list-group-item list-group-item-action ${activeTab === 'new' ? 'active' : ''}`}
              onClick={() => handleTabChange('new')}
            >
              <i className="bi bi-pen me-2"></i> New Message
            </button>
          </div>
        </div>
        <div className="col-md-9">
          {activeTab !== 'new' ? (
            selectedMessage ? (
              <div>
                <button className="btn btn-secondary mb-3" onClick={handleBackClick}>
                  <i className="bi bi-arrow-left me-2"></i>Back
                </button>
                <h2>{selectedMessage.subject}</h2>
                <p><strong>{selectedMessage.from ? 'From:' : 'To:'}</strong> {selectedMessage.from || selectedMessage.to}</p>
                <p>{selectedMessage.content}</p>
              </div>
            ) : (
              <div>
                <h2>{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
                <ul className="list-group">
                  {filteredMessages.map(message => (
                    <li 
                      key={message.id} 
                      className="list-group-item list-group-item-action"
                      onClick={() => handleMessageClick(message)}
                    >
                      <strong>{message.from || `To: ${message.to}`}</strong>
                      <br />
                      <strong>Subject:</strong> {message.subject}
                    </li>
                  ))}
                </ul>
              </div>
            )
          ) : (
            <div>
              <h2>New Message</h2>
              <form onSubmit={handleSendMessage}>
                <div className="mb-3">
                  <label htmlFor="to" className="form-label">To:</label>
                  <input 
                    type="email" 
                    className="form-control" 
                    id="to" 
                    name="to"
                    value={newMessage.to}
                    onChange={handleNewMessageChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">Subject:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="subject" 
                    name="subject"
                    value={newMessage.subject}
                    onChange={handleNewMessageChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="content" className="form-label">Message:</label>
                  <textarea 
                    className="form-control" 
                    id="content" 
                    name="content"
                    value={newMessage.content}
                    onChange={handleNewMessageChange}
                    required
                    rows="5"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Send</button>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="row flex-grow-1 h-65" style={{padding: '3px'}}>
        <ColorfulCalendar/>
      </div>
    </div>
  );
}