import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import CampaignOverview from './components/analytics/CampaignOverview';
import AudienceInsights from './components/analytics/AudienceInsights';
import PublisherInsights from './components/analytics/PublisherInsights';
import BudgetPerformance from './components/analytics/BudgetPerformance';
import RealTimeTracker from './components/analytics/RealTimeTracker';
import CampaignSuggestions from './components/analytics/CampaignSuggestations';
function CompanyAnalytics() {
  return (
      <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex flex-row flex-grow-1" style={{ height: '35vh' }}>
            <CampaignOverview/>
            <PublisherInsights/>
            
            
          </div>
          <div className="d-flex flex-row flex-grow-1" style={{ height: '35vh' }}>
          <BudgetPerformance/>
          <AudienceInsights/>
          <RealTimeTracker/>
          </div>
          <div className="d-flex flex-row flex-grow-1" style={{ height: '25vh' }}>
            
            
            <CampaignSuggestions/>
          </div>
      </div>
  );
}

export default CompanyAnalytics;