import React, { useState } from 'react';

const SkrillCard = () => {
  const [skrillEmail, setSkrillEmail] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
    setError('');
  };

  const handleSaveClick = () => {
    // Simple email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!skrillEmail || !emailPattern.test(skrillEmail)) {
      setError('Invalid email address.');
      return;
    }
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setSkrillEmail(e.target.value);
    setError('');
  };

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="card p-4 shadow-lg" style={{ 
        width: '340px',
        height: '215px',
        backgroundColor: '#FF77B7',
        color: 'black', 
        borderRadius: '15px' 
        
        }}>
        <div className="card-body">
          <h5 className="text-center">Skrill Payment Method</h5>
          {/* Skrill Logo */}
          <div className="text-center mb-3">
            <img
              src="https://images.softwaresuggest.com/software_logo/1673328389_Skrill-Logo.png"
              alt="Skrill Logo"
              style={{ width: '50px', height: 'auto' }}
            />
          </div>
          <div className="mt-3">
            {isEditing ? (
              <input
                type="text"
                value={skrillEmail}
                onChange={handleChange}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder={error || 'Skrill Email'}
              />
            ) : (
              <h6 className="text-center">{skrillEmail || 'No email set'}</h6>
            )}
            {error && <small className="text-danger d-block text-center">{error}</small>}
          </div>
          <div className="d-flex justify-content-end mt-3">
            {isEditing ? (
              <button className="btn btn-success btn-sm" onClick={handleSaveClick}>
                Save
              </button>
            ) : (
              <button className="btn btn-light btn-sm" onClick={handleEditClick}>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkrillCard;
