import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import 'bootstrap/dist/css/bootstrap.min.css'

function LineChartComponent() {
  const chartRef = useRef(null)
  const chartInstanceRef = useRef(null)

  useEffect(() => {
    const createChart = () => {
      const ctx = chartRef.current.getContext('2d')
      
      // Data for the chart
      const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
          {
            label: 'UV Index',
            data: [4000, 3000, 2000, 2780, 1890, 2390, 3490],
            fill: false,
            borderColor: '#8884d8',
            tension: 0.1,
          },
        ],
      }

      // Chart configuration
      const config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
              },
            },
            y: {
              title: {
                display: true,
                text: 'UV Index',
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            tooltip: {
              enabled: true,
            },
          },
        },
      }

      // Destroy existing chart if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      // Create new chart instance
      chartInstanceRef.current = new Chart(ctx, config)
    }

    createChart()

    // Cleanup on unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.resize()
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col d-flex align-items-center justify-content-center">
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineChartComponent