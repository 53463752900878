import React from 'react';
import Card from './card';
import '../../../../styles/common/TopRow.css';



function TopRow() {
  const cardData = [
    { title: '20', textLine1: 'Inbox' },
    { title: '140', textLine1: 'Bids' },
    { title: '24', textLine1: 'Campaigns' },
    { title: '20', textLine1: 'Active Campaign' },
    { title: '12', textLine1: 'Hired' },
    { title: '$500', textLine1: 'Balance' },
  ];

  return (
    <div className="card-container">
      {cardData.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          textLine1={card.textLine1}
        />
      ))}
    </div>
  );
}

export default TopRow;