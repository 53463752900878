import React from 'react';
import { Bar } from 'react-chartjs-2';

function PublisherInsights() {
  const data = {
    labels: ['Channel A', 'Channel B', 'Channel C', 'Channel D'],
    datasets: [
      {
        label: 'Reach (in thousands)',
        data: [500, 600, 700, 550],
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
      {
        label: 'Engagement (in thousands)',
        data: [300, 350, 420, 380],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'x',  // Set this option to make it a horizontal bar chart
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Counts (in thousands)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Channels',
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="card w-50 h-100 d-flex flex-column shadow-sm">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">Publisher Insights</h5>
        <div className="flex-grow-1">
          <Bar data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default PublisherInsights;
