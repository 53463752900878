import React from 'react';

function CampaignSuggestions() {
  return (
    <div className="card w-100 h-100 d-flex flex-column shadow-sm">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">Campaign Optimization Suggestions</h5>
        <p>Best Channel Recommendations: Channel C, Channel D</p>
        <p>Optimal Posting Times: Weekdays 6-8 PM</p>
        <p>Suggested Content Types: Video Reviews</p>
      </div>
    </div>
  );
}

export default CampaignSuggestions;