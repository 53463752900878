import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentCard from "./components/billing/PaymentCard";
import PaypalCard from "./components/billing/PaypalCard";
import SkrillCard from "./components/billing/SkrillCard";
import BillingCard from './components/billing/BillingCard';
import BillingStatusCard from './components/billing/BillingStatusCard';




function CompanyBilling() {
  return (
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row flex-grow-1" style={{ height: '35vh' }}>    
            <PaymentCard/>
            <PaypalCard/>
            <SkrillCard/>
          </div>
          <div className="d-flex flex-row flex-grow-1" style={{ height: '60vh' }}>    
            <BillingCard/>
            <BillingStatusCard/>            
          </div>
      </div>
  );
}

export default CompanyBilling;