import React, { useState } from 'react';
import LeftColumn from './left/left_menu';
import RightColumn from './right/right_board';
import 'bootstrap/dist/css/bootstrap.min.css';
import Campaign from '../../pages/Campaing';
import CompanyProfile from '../../pages/company/CompanyProfile';
import CompanyAnalytics from '../../pages/company/CompanyAnalytics';
import CompanyMessaging from '../../pages/company/CompanyMessaging';
import CompanyBilling from '../../pages/company/CompanyBilling';
import SettingsPage from '../../pages/company/CompanySettings';
import ReportPage from '../../pages/company/ReportPage';

function CompanyDashboard() {
  const [activeMenuItem, setActiveMenuItem] = useState('Dashboard');

  const renderRightColumn = () => {
    switch (activeMenuItem) {
      case 'Dashboard':
        return <RightColumn activeMenuItem={activeMenuItem} />;
      case 'Campaigns':
        return <Campaign/>;
      case 'Analytics':
        return <CompanyAnalytics/> ; //Analytics Column Here
      case 'Messaging':
        return <CompanyMessaging/> ; //Messaging Column Here
      case 'Reports':
        return  <ReportPage/> ; //Messaging Column Here
      case 'Billing':
        return <CompanyBilling/> ; //Messaging Column Here
      case 'Profile':
        return <CompanyProfile/> ; //Settings Column Here
      case 'Settings':
        return <SettingsPage/> ; //Settings Column Here
      default:
        return <RightColumn />; // Fallback to 'Dashboard' view
    }
  };

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
      <div className="col-md-2 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#28cbff', height: '100vh' }}>
        <LeftColumn setActiveMenuItem={setActiveMenuItem} activeMenuItem={activeMenuItem} />
      </div>
      <div className="col-sm-10">
      {renderRightColumn()}
      </div>
      </div>
    </div>
  );
}

export default CompanyDashboard;
