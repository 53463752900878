import React from 'react';

const ReportDetails = ({ report, onAction }) => {
    return (
        <div className="card w-100"> {/* Added w-100 to take up full width */}
            <div className="card-body">
                <h4 className="card-title">{report.creatorName}'s Report</h4>
                <p className="card-text">Campaign: {report.details}</p>
                <p className="card-text">Views: {report.views}</p>

                {/* Video Section */}
                <div className="embed-responsive embed-responsive-16by9 mb-3">
                    <iframe
                        className="embed-responsive-item"
                        src={report.videoUrl}
                        title="Video Report"
                        allowFullScreen
                    ></iframe>
                </div>

                {/* Requirements Checklist */}
                <h5>Requirements Fulfillment</h5>
                <ul className="list-group mb-3">
                    {report.requirements.map(req => (
                        <li key={req.id} className="list-group-item d-flex justify-content-between align-items-center">
                            {req.label}
                            <div>
                                <input
                                    type="radio"
                                    checked={req.fulfilled}
                                    readOnly
                                />{' '}
                                {req.fulfilled ? "Fulfilled" : "Not Fulfilled"}
                            </div>
                        </li>
                    ))}
                </ul>

                {/* Action Buttons */}
                <div className="d-flex justify-content-between">
                    <button className="btn btn-success" onClick={() => onAction('Accepted')}>Accept</button>
                    <button className="btn btn-warning" onClick={() => onAction('Rework')}>Rework</button>
                    <button className="btn btn-danger" onClick={() => onAction('Rejected')}>Reject</button>
                </div>
            </div>
        </div>
    );
};

export default ReportDetails;
