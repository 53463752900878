import React from 'react';

const UpdateEmailCard = ({ email, onEmailChange }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <h5 className="card-title">Update Email</h5>
        <div className="form-group">
          <label htmlFor="formEmail">New Email</label>
          <input
            type="email"
            className="form-control"
            id="formEmail"
            placeholder="Enter new email"
            value={email}
            onChange={(e) => onEmailChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateEmailCard;