import React, { useState } from 'react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 
import clientImg from '../res/login/contenter.png';
import companyImg from '../res/login/company.png';
import { getToken } from '../services/login/authService';
import { decodeToken } from '../services/login/jwtService';


function LoginPage() {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  const navigate = useNavigate();

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowLoginForm(true);
    setShowRegisterForm(false); // Ensure register form is hidden
  };

  const handleCancel = () => {
    setShowLoginForm(false);
    setSelectedImage(null);
    setShowRegisterForm(false);
  };

  const usernameRef = useRef(null);  // Create ref for username input
  const passwordRef = useRef(null);  // Create ref for password input


  const handleLogin = async () => {
    const username = usernameRef.current.value;  // Get value from ref
    const password = passwordRef.current.value;  // Get value from ref

    if (!username || !password) {
      alert('Please fill in both username and password');
      return;
    }

    // Fetch the token using getToken service
    const token = await getToken(username, password);
    if (!token) {
      return;  // Stop if token is not fetched
    }

    // Decode the token using decodeToken service
    const decodedToken = decodeToken(token);
    if (!decodedToken) {
      alert('Failed to decode token');
      return;
    }

    const userRoles = decodedToken.realm_access?.roles || [];
    const companyRoles = decodedToken.resource_access?.['vloger-backend']?.roles || [];

    // Determine the selected login type and validate the roles
    if (selectedImage === 'client') {
      if (userRoles.includes('user')) {
        navigate('/dashboard/user');  // Navigate to user dashboard
      } else {
        alert('You do not have the required role to log in as a client.');
      }
    } else if (selectedImage === 'company') {
      if (companyRoles.includes('company')) {
        navigate('/dashboard/company');  // Navigate to company dashboard
      } else {
        alert('You do not have the required role to log in as a company.');
      }
    } else {
      alert('Please select a valid login type.');
    };
  };
  

  const handleRegister = () => {
    setShowLoginForm(false);
    setShowRegisterForm(true);
  };

  const handleRegisterCancel = () => {
    setShowRegisterForm(false);
  };

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        {/* Left Column */}
        <div className="col-md-8 d-flex align-items-center justify-content-center" style={{ backgroundColor: '#28cbff', height: '100vh' }}>
          <h1 className="text-white" style={{ fontSize: '76px' }}>Vloger.live</h1>
        </div>

        {/* Right Column */}
        <div className="col-md-4 bg-light d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
          {!showLoginForm && !showRegisterForm && (
            <div className="d-flex flex-column align-items-center">
              <div>
                <button className="btn btn-link" onClick={() => handleImageClick('client')}>
                  <img src={clientImg} alt="Vlogger" style={{ maxWidth: '150px', maxHeight: '150px', width: '100%', height: 'auto', border: '1px solid #ccc', borderRadius: '10px'}} />
                  <div>Vlogger</div>
                </button>
                <button className="btn btn-link" onClick={() => handleImageClick('company')}>
                  <img src={companyImg} alt="Company" style={{ maxWidth: '150px', maxHeight: '150px', width: '100%', height: 'auto', border: '1px solid #ccc', borderRadius: '10px'}} />
                  <div>Company</div>
                </button>
              </div>
              <div>Open an account :<button className="btn btn-link" onClick={handleRegister}>Register</button></div>
            </div>
          )}

          {showLoginForm && (
            <form className="d-flex flex-column align-items-center">
              <div className="mb-3">
                <img src={selectedImage === 'client' ? clientImg : companyImg} alt={selectedImage === 'client' ? "Client" : "Company"} style={{ maxWidth: '150px', maxHeight: '150px', width: '100%', height: 'auto', border: '1px solid #ccc', borderRadius: '10px' }} />
              </div>
              <div className="form-group">
                <input 
                type="text"
                id="usernameInput" 
                className="form-control mb-3" 
                placeholder="Username"
                ref={usernameRef} 
                />
                
                <input 
                type="password"
                id="passwordInput"  
                className="form-control mb-3" 
                placeholder="Password"
                ref={passwordRef}
                 />
              </div>
              <div className="d-flex">
                <button type="button" className="btn btn-primary mr-3" onClick={handleLogin}>Login</button>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
              </div>
            </form>
          )}

          {showRegisterForm && (
            <form className="d-flex flex-column align-items-center">
              {/* Register Form Fields */}
              {/* You can add fields for registration */}
              <div className="form-group">
                <input type="text" className="form-control mb-3" placeholder="Full Name" />
                <input type="email" className="form-control mb-3" placeholder="Email" />
                <input type="password" className="form-control mb-3" placeholder="Password" />
                <input type="password" className="form-control mb-3" placeholder="Confirm Password" />
                <input type="text" className="form-control mb-3" placeholder="Address" />
                <input type="text" className="form-control mb-3" placeholder="Phone Number" />
              </div>
              <div className="d-flex">
                <button type="button" className="btn btn-primary mr-3" onClick={handleRegister}>Register</button>
                <button type="button" className="btn btn-secondary" onClick={handleRegisterCancel}>Cancel</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
