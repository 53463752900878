import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Membership({ membershipCount = 4 }) {
  const images = [
    require('../../../res/membership/bronze.png'),
    require('../../../res/membership/silver.png'),
    require('../../../res/membership/gold.png'),
    require('../../../res/membership/diamond.png'),
  ];

  // Ensure membershipCount is between 1 and 4
  const validRewardCount = Math.max(1, Math.min(membershipCount, 4));

  return (
    <div className="d-flex flex-row justify-content-center align-items-center mb-4 mt-1">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`reward-${index + 1}`}
          className="img-fluid membership-image"
          style={{
            width: '50px',
            height: '50px',
            filter: index < validRewardCount ? 'none' : 'grayscale(100%)',
            opacity: index < validRewardCount ? '1' : '0.5',
          }}
        />
      ))}
    </div>
  );
}

export default Membership;
