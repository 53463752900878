const keycloakClientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const keycloakClientSecret = process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET;
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL;



export const getToken = async (username, password) => {
  
    const params = new URLSearchParams();
    params.append('client_id', keycloakClientId);  // Replace with your actual client ID
    params.append('grant_type', 'password');
    params.append('client_secret', keycloakClientSecret);
    params.append('username', username);
    params.append('password', password);
  
    try {
      const response = await fetch(keycloakUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params,
      });
  
      if (!response.ok) {
        throw new Error('Failed to get token');
      }
  
      const data = await response.json();
      localStorage.setItem('jwt_token', data.access_token);  // Save the token in localStorage
      return data.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
      alert('Login failed. Please check your credentials.');
      return null;
    }
  };