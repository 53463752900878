import React, { useState } from 'react';

const PaymentCard = () => {
  const [cardData, setCardData] = useState({
    cardNumber: '**** **** **** 1234',
    cardHolder: 'Your Name',
    expiryDate: 'MM/YY',
    cvv: '***',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [tempData, setTempData] = useState({ ...cardData });
  const [errors, setErrors] = useState({});

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    const newErrors = {};

    if (!tempData.cardNumber || tempData.cardNumber.replace(/\s/g, '').length !== 16) {
      newErrors.cardNumber = 'Must be 16 digits.';
    }
    if (!tempData.cardHolder) {
      newErrors.cardHolder = 'Name is required.';
    }
    if (!tempData.expiryDate) {
      newErrors.expiryDate = 'Expiry date is required.';
    }
    if (!tempData.cvv || tempData.cvv.length !== 3) {
      newErrors.cvv = 'Must be 3 digits.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const maskedCardNumber = `**** **** **** ${tempData.cardNumber.slice(-4)}`;
    const maskedCVV = tempData.cvv.replace(/\d/g, '*');

    setCardData({ ...tempData, cardNumber: maskedCardNumber, cvv: maskedCVV });
    setErrors({});
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

    if (name === 'cardNumber') {
      const formattedValue = value
        .replace(/\D/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      setTempData({ ...tempData, [name]: formattedValue });
    } else {
      setTempData({ ...tempData, [name]: value });
    }
  };

  const getCardType = () => {
    const firstDigit = tempData.cardNumber[0];
    if (firstDigit === '4') return 'Visa';
    if (firstDigit === '5') return 'Mastercard';
    return null;
  };

  const getErrorPlaceholder = (field) => errors[field] || '';

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div
        className="card p-4 shadow-lg"
        style={{
          width: '340px',
          height: '215px',
          backgroundColor: '#D4AF37',
          color: 'black',
          borderRadius: '15px',
        }}
      >
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div style={{ width: '70%' }}>
              {isEditing ? (
                <input
                  type="text"
                  name="cardNumber"
                  value={tempData.cardNumber}
                  onChange={handleChange}
                  className={`form-control text-center ${errors.cardNumber ? 'is-invalid' : ''}`}
                  placeholder={getErrorPlaceholder('cardNumber') || 'Card Number'}
                  maxLength="19"
                  style={{ borderColor: errors.cardNumber ? 'red' : undefined }}
                />
              ) : (
                <h4 className="text-center">{cardData.cardNumber}</h4>
              )}
            </div>
            <div>
              {getCardType() === 'Visa' && (
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg" alt="Visa" width="50" />
              )}
              {getCardType() === 'Mastercard' && (
                <img src="https://upload.wikimedia.org/wikipedia/commons/a/a4/Mastercard_2019_logo.svg" alt="Mastercard" width="50" />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ width: '60%' }}>
              {isEditing ? (
                <input
                  type="text"
                  name="cardHolder"
                  value={tempData.cardHolder}
                  onChange={handleChange}
                  className={`form-control ${errors.cardHolder ? 'is-invalid' : ''}`}
                  placeholder={getErrorPlaceholder('cardHolder') || 'Card Holder'}
                  style={{ borderColor: errors.cardHolder ? 'red' : undefined }}
                />
              ) : (
                <h6>{cardData.cardHolder}</h6>
              )}
            </div>
            <div style={{ width: '30%' }}>
              {isEditing ? (
                <input
                  type="text"
                  name="expiryDate"
                  value={tempData.expiryDate}
                  onChange={handleChange}
                  className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                  placeholder={getErrorPlaceholder('expiryDate') || 'MM/YY'}
                  maxLength="5"
                  style={{ borderColor: errors.expiryDate ? 'red' : undefined }}
                />
              ) : (
                <h6>{cardData.expiryDate}</h6>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div style={{ width: '30%' }}>
              {isEditing ? (
                <input
                  type="password"
                  name="cvv"
                  value={tempData.cvv}
                  onChange={handleChange}
                  className={`form-control ${errors.cvv ? 'is-invalid' : ''}`}
                  placeholder={getErrorPlaceholder('cvv') || 'CVV'}
                  maxLength="3"
                  style={{ borderColor: errors.cvv ? 'red' : undefined }}
                />
              ) : (
                <h6>{cardData.cvv}</h6>
              )}
            </div>
            <div className="d-flex justify-content-end ms-auto">
              {isEditing ? (
                <button className="btn btn-success btn-sm" onClick={handleSaveClick}>
                  Save
                </button>
              ) : (
                <button className="btn btn-light btn-sm" onClick={handleEditClick}>
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
