import React from 'react';
import { Line } from 'react-chartjs-2';

function BudgetPerformance() {
  const data = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8'],
    datasets: [
      {
        label: 'Budget Utilization ($)',
        data: [1000, 2000, 1500, 3000, 3000, 2500, 2000, 3500],
        fill: true,
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        tension: 0.3,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="card w-75 h-100 d-flex flex-column shadow-sm">
      <div className="card-body d-flex flex-column">
        <h5 className="card-title">Budget & Performance Tracking</h5>
        <div className="flex-grow-1">
            <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default BudgetPerformance;