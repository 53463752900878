import React from 'react';
import { Pie } from 'react-chartjs-2';

function AudienceInsights() {
  const data = {
    labels: ['Tech', 'Gaming', 'Lifestyle', 'Education'],
    datasets: [
      {
        label: 'Audience Interests',
        data: [30, 25, 20, 25],
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="card shadow-sm">
      <div className="card-body">
        <h5 className="card-title">Audience Insights</h5>
        <Pie data={data} />
      </div>
    </div>
  );
}

export default AudienceInsights;
