import React, { useState } from 'react';

const PaypalCard = () => {
  const [paypalEmail, setPaypalEmail] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
    setError('');
  };

  const handleSaveClick = () => {
    // Simple email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!paypalEmail || !emailPattern.test(paypalEmail)) {
      setError('Invalid email address.');
      return;
    }
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setPaypalEmail(e.target.value);
    setError('');
  };

  return (
    <div className="container mt-5 d-flex justify-content-center">
      <div className="card p-4 shadow-lg" style={{ 
        
        width: '340px',
        height: '215px',
        backgroundColor: '#169BD7',
        color: 'black', 
        borderRadius: '15px' 
        
        }}>
        <div className="card-body">
          <h5 className="text-center">PayPal Payment Method</h5>
          {/* PayPal Logo */}
          <div className="text-center mb-3">
            <img
              src="https://www.paypalobjects.com/webstatic/icon/pp258.png"
              alt="PayPal Logo"
              style={{ width: '30px', height: 'auto' }}
            />
          </div>
          <div className="mt-3">
            {isEditing ? (
              <input
                type="text"
                value={paypalEmail}
                onChange={handleChange}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder={error || 'PayPal Email'}
              />
            ) : (
              <h6 className="text-center">{paypalEmail || 'No email set'}</h6>
            )}
            {error && <small className="text-danger d-block text-center">{error}</small>}
          </div>
          <div className="d-flex justify-content-end mt-3">
            {isEditing ? (
              <button className="btn btn-success btn-sm" onClick={handleSaveClick}>
                Save
              </button>
            ) : (
              <button className="btn btn-light btn-sm" onClick={handleEditClick}>
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalCard;
