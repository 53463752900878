import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const userProfile = {
  companyName: "TechInnovators",
  companyLogo: "/img/contenter.jpg"
};

const hiredUsers = {
  1: { name: 'JohnDoe', avatar: '/img/user1.jpg' },
  2: { name: 'JaneSmith', avatar: '/img/user2.jpg' }
};

function Campaign() {
  const [campaigns, setCampaigns] = useState([
    { id: 1, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 2, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },
    { id: 3, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 4, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },
    { id: 5, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 6, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },
    { id: 7, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 8, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },
    { id: 9, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 10, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },
    { id: 11, title: "Gaming Product Review", description: "Looking for gaming YouTubers to review our new controller", budget: "$500", requirements: "10k+ subscribers, gaming niche", companyName: "GameTech", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Active", hiredUserId: 1 },
    { id: 12, title: "Tech Unboxing Video", description: "Seeking tech YouTubers for unboxing our latest smartphone", budget: "$1000", requirements: "50k+ subscribers, tech niche", companyName: "TechGiant", companyLogo: "/placeholder.svg?height=40&width=40", productImage: "/placeholder.svg?height=200&width=200", status: "Pending", hiredUserId: 1 },

  ]);

  const [newCampaign, setNewCampaign] = useState({
    title: '',
    description: '',
    budget: '',
    requirements: '',
    productImage: '',
  });

  const [activeTab, setActiveTab] = useState('create'); // For controlling tabs

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCampaign(prev => ({ ...prev, [name]: value }));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewCampaign(prev => ({ ...prev, [e.target.name]: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const campaignWithId = {
      ...newCampaign,
      id: campaigns.length + 1,
      companyName: userProfile.companyName,
      companyLogo: userProfile.companyLogo,
      status: "Active" // Default status to active
    };
    setCampaigns(prev => [...prev, campaignWithId]);
    setNewCampaign({ title: '', description: '', budget: '', requirements: '', productImage: '' });
  };

  return (
    <div className="container-fluid">
      {/* Upper Row: Tabbed Section and Placeholder */}
      <div className="d-flex flex-row flex-grow-1" style={{ height: '60vh', padding: '10px' }}>
        {/* Left Column: Tabs */}
        <div className="col-md-6" style={{ border: '1px solid grey', padding: '10px', borderRadius: '5px' }}>
          {/* Tabs */}
          <ul className="nav nav-tabs" style={{ padding: '1px', backgroundColor: '#f72a4c', borderRadius: '5px' }}>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === 'create' ? 'active' : ''}`}
                onClick={() => setActiveTab('create')}
              >
                Create Campaign
              </button>
            </li>
            <li className="nav-item" style={{ backgroundColor: '#fafafa', borderRadius: '5px' }}>
              <button
                className={`nav-link ${activeTab === 'update' ? 'active' : ''}`}
                onClick={() => setActiveTab('update')}
              >
                Update Campaign
              </button>
            </li>
          </ul>

          {/* Tab Content */}
          <div className="tab-content p-3">
            {activeTab === 'create' && (
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                  <div className="mb-3 w-100">
                    <label htmlFor="title" className="form-label">Campaign Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      value={newCampaign.title}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="d-flex flex-wrap mb-3 w-100">
                    <div className="col-md-6 mb-3 pe-md-2">
                      <label htmlFor="budget" className="form-label">Budget</label>
                      <input
                        type="text"
                        className="form-control"
                        id="budget"
                        name="budget"
                        value={newCampaign.budget}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3 ps-md-2">
                      <label htmlFor="requirements" className="form-label">Requirements</label>
                      <input
                        type="text"
                        className="form-control"
                        id="requirements"
                        name="requirements"
                        value={newCampaign.requirements}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="mb-3 w-100">
                    <label htmlFor="description" className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      rows="3"
                      value={newCampaign.description}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="mb-3 w-100">
                    <label htmlFor="productImage" className="form-label">Product Image</label>
                    <input
                      type="file"
                      className="form-control"
                      id="productImage"
                      name="productImage"
                      accept="image/*"
                      onChange={handleFileUpload}
                      required
                    />
                  </div>

                  <button type="submit" className="btn btn-primary w-25">Create Campaign</button>
                </div>
              </form>



            )}

            {activeTab === 'update' && (
              <div className="p-3">
                {/* Placeholder for updating existing campaigns */}
                <p>Select a campaign to update.</p>
              </div>
            )}
          </div>
        </div>

        {/* Right Column: Campaign List */}
        <div className="col-md-6 d-flex flex-column" style={{ border: '1px solid grey', borderRadius: '5px' }}>
          <h4 style={{ position: 'sticky', padding: '10px', top: '0', backgroundColor: '#4287f5', zIndex: '1', borderRadius: '5px' }}>All Campaigns</h4>
          <div className="list-group flex-grow-1" style={{ overflowY: 'auto', padding: '5px' }}>
            <ul className="list-group">
              {campaigns.map((campaign) => {
                // Set contextual class based on campaign status
                let listItemClass = 'list-group-item';
                if (campaign.status === 'Active') {
                  listItemClass += ' list-group-item-success';  // Light blue for active
                } else if (campaign.status === 'Expired') {
                  listItemClass += ' list-group-item-danger';  // Light red for expired
                } else if (campaign.status === 'Expiring Soon') {
                  listItemClass += ' list-group-item-warning';  // Yellow for expiring soon
                } else if (campaign.status === 'Success') {
                  listItemClass += ' list-group-item-success';  // Light green for success
                }

                return (
                  <li key={campaign.id} className={`${listItemClass} d-flex justify-content-between align-items-center`} style={{ border: '1px solid grey', borderRadius: '5px' }}>
                    <div>{campaign.title}</div>
                    <div>{campaign.budget}</div>
                    <span className={`badge ${campaign.status === 'Active' ? 'bg-success' : 'bg-warning'} rounded-pill`}>
                      {campaign.status}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Row: Active Campaigns */}
      <div style={{ padding: '10px', marginTop: '10px', border: '1px solid grey', borderRadius: '5px' }}>
        <div className='d-flex flex-row'>
          <div className='d-flex flex-column flex-grow-1' style={{ margin: '2px' }}>
            <h4 style={{ position: 'sticky', padding: '10px', top: '0', backgroundColor: '#04c243', zIndex: '1', borderRadius: '5px' }}>Active Campaigns</h4>
            <div style={{ height: '25vh', overflowY: 'auto' }}>
              <ul className="list-group">
                {campaigns
                  .filter(campaign => campaign.status === 'Active')
                  .map((campaign) => (
                    <li key={campaign.id} className="list-group-item d-flex justify-content-between align-items-center" style={{ border: '1px solid grey', borderRadius: '5px' }}>
                      <div>{campaign.title} - {campaign.budget}</div>
                      <div className="d-flex align-items-center">
                        <img
                          alt='user'
                          src={hiredUsers[campaign.hiredUserId]?.avatar}
                          className="rounded-circle me-2"
                          style={{ width: '30px', height: '30px' }}
                        />
                        <span>{hiredUsers[campaign.hiredUserId]?.name || 'anonymous'}</span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

          </div>
          <div className='d-flex flex-column flex-grow-1' style={{ margin: '2px' }}>
            <h4 style={{ position: 'sticky', padding: '10px', top: '0', backgroundColor: '#f5dd2a', zIndex: '1', borderRadius: '5px' }}>Reports</h4>
            <div style={{ height: '25vh', overflowY: 'auto' }}>
              <ul className="list-group">
                {campaigns
                  .filter(campaign => campaign.status === 'Active')
                  .map((campaign) => (
                    <li key={campaign.id} className="list-group-item d-flex justify-content-between align-items-center" style={{ border: '1px solid grey', borderRadius: '5px' }}>
                      <div>{campaign.title} - {campaign.budget}</div>
                      <div className="d-flex align-items-center">
                        <img
                          alt='user'
                          src={hiredUsers[campaign.hiredUserId]?.avatar}
                          className="rounded-circle me-2"
                          style={{ width: '30px', height: '30px' }}
                        />
                        <span>{hiredUsers[campaign.hiredUserId]?.name || 'anonymous'}</span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Campaign;